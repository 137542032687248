<script lang="ts" setup>
  import type * as contentful from 'contentful'
  import type { JsonObject } from 'contentful/node_modules/type-fest'
  import type { Entry } from 'contentful'
  import type { TypeFooterSkeleton, TypeHeaderSkeleton, TypePageSkeleton } from '@/types/contentful'

  useOrderAtTable()

  const { data, error } =
    await useFetch<Entry<TypePageSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string>>(`/api/cms/page/home`)

  if (!data.value || error.value) {
    throw createError({
      ...error.value,
      statusCode: 404,
      statusMessage: 'No Home Data. Page not found',
      fatal: true
    })
  }

  /**
   * Get navData from layout to dynamically generate Organisation Schema JSONld
   */
  const layoutData = useState<{
    footer: contentful.Entry<TypeFooterSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string>
    header: contentful.Entry<TypeHeaderSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string>
  }>('layoutData')

  useGrilldServerSeoMeta(data.value?.fields.seo)
  useGrilldSeoHead({
    pageTitle: data.value?.fields.seo?.fields.metaTitle ?? undefined,
    canonicalUrl: data.value?.fields.seo?.fields.canonicalUrl ?? undefined,
    jsonLinkingData: [
      data.value?.fields.seo?.fields.jsonLinkingData as JsonObject,
      dynamicOrganisationSchema(data.value, layoutData.value) as JsonObject
    ]
  })

  /**
   * @description if query param 'order-at-table' is present, open the order-at-table page in the ordering app
   */
  function useOrderAtTable() {
    const query = useRoute().query

    if (query['order-at-table']) {
      const { orderingAppNavTo } = useOrderingAppNavigation()

      orderingAppNavTo('/order-at-table/' + query['order-at-table'])
    }
  }
</script>

<template>
  <MPageModules v-if="data" :page-data="data" />
</template>
